import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const host = process.env.REACT_APP_HOST;
const gaConfig = {
  local: "",
  dev: "G-N8LHV98W52",
  beta: "G-RCDEPGDD9V",
  prod: "G-YM0CNTD7ZD"
}

const useGaTracker = (loginData) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let GA_ID = "";

    if (window.location.href.includes("localhost")) {
      GA_ID = gaConfig.local;
    } else if (host.includes('dev')) {
      GA_ID = gaConfig.dev;
    } else if (host.includes('beta')) {
      GA_ID = gaConfig.beta;
    } else if (host.includes('portal')) {
      GA_ID = gaConfig.prod;
    }
    if (!loginData.userId) {
      return;
    }

    if (GA_ID) {
      ReactGA.initialize(GA_ID, {
        debug: true,
        gaOptions: {
          user_name: loginData.tags.Username.replace('@', '_at_').replace('.', '_dot_'),
          client_name: loginData.tags.ClientName,
          cookieDomain: "none",
          siteSpeedSampleRate: 100
        }
      });
      setInitialized(true);
    }
  }, [loginData]);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        user_id: loginData.userId,
        client_name: loginData.tags.ClientName,
        user_name: loginData.tags.Username.replace('@', '_at_').replace('.', '_dot_'),
        page: location.pathname,
        title: location.pathname.replace('/', '').replace(/([A-Z])/g, ' $1').trim()
      });
    }
  }, [initialized, location]);
};

export default useGaTracker;